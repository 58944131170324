export const state = () => ({
  tests: {},
});

export const mutations = {
  SET_AB_TEST(state, { testName, variantName }) {
    state.tests[testName] = variantName;
  },
};

export const actions = {
  setAbTest({ commit }, { testName, variantName }) {
    commit('SET_AB_TEST', { testName, variantName });
  },
};
