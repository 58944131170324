//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'WhatsappWidget',
  props: {
    href: {
      type: String,
      required: true,
    },
    timeout: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      isVisible: false,
      timer: null,
    };
  },
  computed: {
    imageUrl() {
      return require('~/assets/images/WhatsAppWidgetIcon.png');
    },
    imageSrcSet() {
      return `${require('~/assets/images/WhatsAppWidgetIcon_2x.png')} 2x, ${require('~/assets/images/WhatsAppWidgetIcon.png')} 1x`;
    },
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.isVisible = true;
    }, this.timeout * 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
