//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import isMobile from '../mixins/isMobile.mixin';
import setDefaultCookie from '../mixins/setDefaultCookie.mixin';
import cacheTimeQuery from '@/mixins/cacheTimeQuery';
import { addBackToTop } from 'vanilla-back-to-top';
import SaleBannerV2 from '../components/saleBannerV2';
import AuthDialog from './components/authDialog';
import UserAvatar from '../components/userAvatar';
import PaymentSystemList from '../components/d0822/components/paymentSystems/paymentSystemList';
import DropdownArrow from '~/assets/svg/dropdown-new.svg?inline';
import DropdownSharktooth from '~/assets/svg/dropdown-sharktooth.svg?inline';

export default {
  components: {
    UserAvatar,
    SaleBannerV2,
    AuthDialog,
    DropdownArrow,
    DropdownSharktooth,
    PaymentSystemList,
  },
  mixins: [isMobile, setDefaultCookie, cacheTimeQuery],
  data() {
    return {
      isDisplayAuthDialog: false,
      saleBannerExcludeRoutes: [
        `webinars-direction___${this.$app.config.current.localeName}`,
        `webinarDetailed___${this.$app.config.current.localeName}`,
        `sale___${this.$app.config.current.localeName}`,
        `sale-sale___${this.$app.config.current.localeName}`,
        `sale-thankyou___${this.$app.config.current.localeName}`,
        `webinarSale___${this.$app.config.current.localeName}`,
        `webinarSaleNew___${this.$app.config.current.localeName}`,
        `webinars-slug___${this.$app.config.current.localeName}`,
        `payment-generate___${this.$app.config.current.localeName}`,
        `payment-something-went-wrong___${this.$app.config.current.localeName}`,
        `payment-success___${this.$app.config.current.localeName}`,
      ],
      saleBannerIsExternal:
        this.$app.config.current.saleBanner.eternalV2 ||
        this.$app.config.current.saleBanner.eternal,
      saleBannerTopPositionRoutes: [
        `webinars-slug___${this.$app.config.current.localeName}`,
      ],
      backToTopButtonExcludeRoutes: [
        `webinarDetailed___${this.$app.config.current.localeName}`,
        //`career-center-students___pt-br`,
      ],
      isShowSaleBanner: false,
      isNavMenu: false,
      isMenuExpanded: false,
    };
  },
  head() {
    return {
      __dangerouslyDisableSanitizers: ['script'],
      ...(this.$store.state.webinars.abTestPrefixActive
        ? {
            link: [
              {
                rel: 'canonical',
                href:
                  'https://' +
                  this.$app.config.current.domain +
                  this.$route.path.slice(
                    0,
                    this.$route.path.lastIndexOf(
                      this.$store.state.webinars.abTestPrefix,
                    ),
                  ),
              },
            ],
          }
        : {
            link: [
              {
                rel: 'canonical',
                href:
                  'https://' +
                  this.$app.config.current.domain +
                  this.$route.path,
              },
            ],
          }),
      script: [
        {
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "http://schema.org/",
            "@type": "Organization",
            "name": "EBAC",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Rua Aureliano Coutinho, 109 - Vila Buarque",
              "addressLocality": "São Paulo",
              "addressRegion": "São Paulo",
              "postalCode": ""
            },
            "telephone": "${this.$app.config.current.phone}"
          }`,
        },
      ],
    };
  },
  computed: {
    userName() {
      return this.$app.user?.name || '';
    },
    isDisplayFullHeader() {
      const isDisplayShortHeader = this.isDisplayShortHeader;
      return !isDisplayShortHeader;
    },
    isDisplayShortHeader() {
      /*
      utm_source
      utm_medium
      utm_campaign
      utm_content
      utm_term
      * */
      // TODO remove
      return false;

      // const route = this.$route;
      // const hm = route.query.hm;
      // return hm && hm === 'off' ||
      //   route.query.hasOwnProperty('utm_source') ||
      //   route.query.hasOwnProperty('utm_medium') ||
      //   route.query.hasOwnProperty('utm_campaign') ||
      //   route.query.hasOwnProperty('utm_content') ||
      //   route.query.hasOwnProperty('utm_term');
    },
    shortUserName() {
      const user = this.$app.user;
      if (!user) return null;

      const name = user.name;
      // const name = 'Camila Massaneiro';
      const partsOfAName = name.split(' ');

      if (partsOfAName.length > 1) {
        return `${partsOfAName[0]} ${partsOfAName[1]}`;
      } else return partsOfAName[0];
    },
    documents() {
      return this.$app.config.current.footerDocuments.map((val) => {
        let doc = this.$app.config.current.documents[val];
        if (typeof doc === 'string') {
          doc = {
            link: doc,
          };
        }

        doc.documentKey = val;
        return doc;
      });
    },
  },
  watch: {
    '$app.backToTopButtonBottomOffset'() {
      const backToTopElement = document.querySelector('#back-to-top');
      if (backToTopElement) {
        backToTopElement.style.marginBottom = `${this.$app.backToTopButtonBottomOffset}px`;
      }
    },
    $route: {
      handler() {
        if (process.client) {
          window.dispatchEvent(new Event('recalculateCookieConsent'));
        }

        if (!this.saleBannerExcludeRoutes.includes(this.$route.name)) {
          this.isShowSaleBanner = true;
        } else this.isShowSaleBanner = false;
        if (!this.backToTopButtonExcludeRoutes.includes(this.$route.name)) {
          this.toggleIsBackToTopButton(true);
        } else this.toggleIsBackToTopButton(false);
      },
      immediate: true,
    },
    isNavMenu(value) {
      const mainNav = this.$refs.mainNav;
      const menuBtn = this.$refs.navMenuBurger;

      if (value) {
        mainNav.classList.add('container--active');
        document.body.classList.add('overflow-hidden');
        document.body.classList.add('full-vh');
        menuBtn.classList.add('closed');

        mainNav.style.display = 'block';
        mainNav.style.maxHeight = '100vh';
        mainNav.style.opacity = 1;
      } else {
        mainNav.classList.remove('container--active');
        document.body.classList.remove('overflow-hidden');
        document.body.classList.remove('full-vh');
        menuBtn.classList.remove('closed');

        mainNav.style.display = '';
        mainNav.style.maxHeight = '';
        mainNav.style.opacity = '';
      }
    },
  },
  created() {
    if (process.client) {
      // const getCookie = function (name) {
      //   let matches = document.cookie.match(
      //     new RegExp(
      //       '(?:^|; )' +
      //         name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
      //         '=([^;]*)',
      //     ),
      //   );
      //   return matches ? decodeURIComponent(matches[1]) : undefined;
      // };
    }

    if (process.client) {
      if (!document.querySelector('#back-to-top')) {
        addBackToTop({
          cornerOffset: 20, // px
          // ease: 'inOutSine', // any one from https://www.npmjs.com/package/ease-component will do
          id: 'back-to-top',
          innerHTML: `
            <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.049 8.6705C14.4953 9.10983 15.219 9.10983 15.6653 8.6705C16.1116 8.23116 16.1116 7.51884 15.6653 7.0795L8.80812 0.329506C8.37546 -0.0963949 7.67879 -0.111296 7.22775 0.295703L0.370605 6.4832C-0.0946732 6.90304 -0.126104 7.61468 0.300402 8.07269C0.726907 8.5307 1.44984 8.56164 1.91512 8.1418L7.96563 2.68216L14.049 8.6705Z" fill="currentColor"/>
            </svg>
          `,
          onClickScrollTo: 0, // px
          scrollContainer: document.body, // or a DOM element, e.g., document.getElementById('content')
          scrollDuration: 100, // ms
          showWhenScrollTopIs: window.innerHeight * 2,
          textColor: '#fff',
          zIndex: 1,
        });
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.resize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
  },
  methods: {
    resize(e) {
      if (!this.isNavMenu) return;

      if (e.target.innerWidth >= 1024) {
        this.isNavMenu = false;
      }
    },
    openNavMenu() {
      this.isNavMenu = !this.isNavMenu;
    },
    async toggleIsBackToTopButton(isShow) {
      if (process.client) {
        await Vue.nextTick();

        const backToTop = document.querySelector('#back-to-top');

        if (isShow) {
          backToTop.style.display = 'block';
        } else {
          backToTop.style.display = 'none';
        }
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onClickLoginButton() {
      this.isDisplayAuthDialog = true;
    },
    async logout() {
      document.cookie = `a_token=; path=/; domain=${window.location.hostname}; max-age=-1; secure`;
      await this.$store.dispatch('user/logoutFromLms');
      this.$app.user = null;
    },
    userAvatarOnClick() {
      window.open(this.$app.config.current.lmsUrl, '_blank');
    },
    expandMenu() {
      if (!this.isNavMenu) return;
      this.isMenuExpanded = !this.isMenuExpanded;
    },
  },
};
