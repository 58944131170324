import { setCookie } from '@/utils/setCookie';
import cookieparser from 'cookieparser';

const AB_TEST_QUERY_PARAM = 'ab';
const ID_SEPARATOR = '_';
const COOKIE_PREFIX = 'ebac_';
const AB_TEST_COOKIE_TTL = 604800; // 7 дней - договоренность с маркетингом

function isExperimentAllowed(testName, availableExperiments) {
  if (!availableExperiments) return false;
  return Object.prototype.hasOwnProperty.call(availableExperiments, testName);
}

function parseCookies(req) {
  if (process.server && req?.headers?.cookie) {
    return cookieparser.parse(req.headers.cookie);
  } else if (process.client) {
    return document.cookie.split(';').reduce((acc, cookie) => {
      const [name, value] = cookie.trim().split('=');
      acc[name] = value;
      return acc;
    }, {});
  }
  return {};
}

function setAbTest({ store, res }, abTestValue, availableExperiments) {
  let [testName, variantName] = abTestValue.split(ID_SEPARATOR);

  if (
    testName &&
    variantName &&
    isExperimentAllowed(testName, availableExperiments)
  ) {
    const cookieName = `${COOKIE_PREFIX}${AB_TEST_QUERY_PARAM}${ID_SEPARATOR}${testName}`;

    // Устанавливаем куки на клиенте или сервере в зависимости от среды
    if (process.client) {
      setCookie(cookieName, abTestValue, AB_TEST_COOKIE_TTL);
    } else if (process.server && res) {
      // Если выполняется на сервере, используем заголовок Set-Cookie
      res.setHeader(
        'Set-Cookie',
        `${cookieName}=${encodeURIComponent(
          abTestValue,
        )}; Max-Age=${AB_TEST_COOKIE_TTL}; Path=/; HttpOnly`,
      );
    }

    // Записываем AB-тест в store
    store.dispatch('ab/setAbTest', { testName, variantName });
  }
}

function setAbTestFromQueryString(
  { query, store, req, res },
  availableExperiments,
) {
  const abTestValue = query[AB_TEST_QUERY_PARAM];

  if (abTestValue) {
    if (Array.isArray(abTestValue)) {
      abTestValue.forEach((_abTestValue) => {
        setAbTest({ store, req, res }, _abTestValue, availableExperiments);
      });
    } else {
      setAbTest({ store, req, res }, abTestValue, availableExperiments);
    }
  }
}

function setAbTestFromCookie({ store, req }, availableExperiments) {
  const cookies = parseCookies(req);

  // Проверяем куки на наличие AB-тестов и записываем в store
  Object.keys(cookies).forEach((name) => {
    if (
      name.startsWith(`${COOKIE_PREFIX}${AB_TEST_QUERY_PARAM}${ID_SEPARATOR}`)
    ) {
      const abTestValue = decodeURIComponent(cookies[name]);
      const [testName, variantName] = abTestValue.split(ID_SEPARATOR);

      if (
        testName &&
        variantName &&
        isExperimentAllowed(testName, availableExperiments)
      ) {
        store.dispatch('ab/setAbTest', { testName, variantName });
      }
    }
  });
}

function setDefaultAbTestIfNeeded(
  { store, req, res, query },
  availableExperiments,
) {
  if (!availableExperiments) return;

  // Устанавливаем тест по умолчанию только при отсутствии параметра `ab` в query
  if (!query[AB_TEST_QUERY_PARAM] && process.server && req && req.url) {
    const cookies = parseCookies(req);

    Object.entries(availableExperiments).forEach(
      ([experimentName, { page, defaultValue }]) => {
        const cookieName = `${COOKIE_PREFIX}${AB_TEST_QUERY_PARAM}${ID_SEPARATOR}${experimentName}`;

        // Проверяем, нужно ли запускать эксперимент по умолчанию для текущего URL
        if (
          defaultValue &&
          req._parsedUrl.pathname === page &&
          !cookies[cookieName]
        ) {
          setAbTest(
            { store, res },
            `${experimentName}${ID_SEPARATOR}${defaultValue}`,
            availableExperiments,
          );
        }
      },
    );
  }
}

export default function ({ query, store, req, res, $app }) {
  try {
    const availableExperiments = $app.config.current?.experiments;
    if (availableExperiments) {
      // Устанавливаем тесты из куки
      setAbTestFromCookie({ store, req }, availableExperiments);

      // Устанавливаем тесты из query параметра
      setAbTestFromQueryString(
        { query, store, req, res },
        availableExperiments,
      );

      // Устанавливаем тест по умолчанию только при первом посещении через серверный запрос
      setDefaultAbTestIfNeeded(
        { store, req, res, query },
        availableExperiments,
      );
    }
  } catch (e) {
    console.error(e);
  }
}
