/**
 * Получение IP-адреса пользователя с помощью сервиса Ipify или CloudFlare
 * */
export default async function () {
  /**
   * Получение IP-адреса пользователя с помощью сервиса CloudFlare
   * */
  const getUserIpFromCloudFlare = async () => {
    const cloudflareData = await this.$axios({
      method: 'get',
      url: `https://www.cloudflare.com/cdn-cgi/trace`,
    });

    const ipList = cloudflareData.data.match(/ip=(.*)/m) || [];
    return ipList[1];
  };

  /**
   * Получение IP-адреса пользователя с помощью сервиса Ipify
   * */
  const getUserIpFromIpify = async () => {
    const cloudflareData = await this.$axios({
      method: 'get',
      url: `https://api.ipify.org/?format=json`,
    });

    return cloudflareData.data?.ip;
  };

  /**
   * Получение IP-адреса пользователя с помощью нашей lambda
   * */
  const getUserIpFromCloudFront = async () => {
    const lambdaData = await this.$axios({
      method: 'get',
      url: this.$app?.config?.current?.cloudFrontInfoUrl,
    });

    return lambdaData.data?.['viewer-ip'];
  };

  /**
   * Попытка получения IP с помощью сервиса Ipify
   * */
  // try {
  //   return await getUserIpFromIpify();
  // } catch (error) {
  //   console.error(error);
  // }

  /**
   * Попытка получения IP с помощью нашей lambda
   * */
  try {
    return await getUserIpFromCloudFront();
  } catch (error) {
    console.error(error);
  }

  /**
   * В случае неудачи пробуем получить IP с помощью сервиса CloudFlare
   * */
  try {
    return await getUserIpFromCloudFlare();
  } catch (error) {
    console.error(error);
  }
}
