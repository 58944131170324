import dayjs from 'dayjs'

import 'dayjs/locale/pt-br'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(localizedFormat)

dayjs.locale('pt-br')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
