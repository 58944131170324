//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HintEmail',
  props: [
    'value',
    'label',
    'errorMessages',
    'nextComponent',
    'maxNumberOfTips',
    'disabled',
  ],
  data() {
    return {
      domains: [
        'gmail.com',
        'hotmail.com',
        'outlook.com',
        'yahoo.com.br',
        'icloud.com',
        'live.com',
        'yahoo.com',
        'bol.com.br',
        'gmail.com',
        'uol.com.br',
        'outlook.com.br',
        'terra.com.br',
      ],
      attachNode: null,
      serverSide: true,
    };
  },
  computed: {
    formValue: {
      set: function (value) {
        this.$emit('input', value ? value.trim() : value);
      },
      get: function () {
        return this.value;
      },
    },
    showMenu() {
      const email = this.formValue || '';
      return (email.match(/@/g) || []).length === 1;
    },
    emailComputed() {
      const email = this.formValue;
      if (email === null) return [];
      const [partEmail, domain] = email.split('@');
      const examples = [email];
      if (domain === '') {
        examples.push(
          ...this.domains
            .slice(0, this.maxNumberOfTips || 4)
            .map((domain) => `${partEmail}@${domain}`),
        );
      } else {
        examples.push(
          ...this.domains
            .filter((_domain) => new RegExp(`^${domain}`).test(_domain))
            .slice(0, this.maxNumberOfTips || 4)
            .map((_domain) => `${partEmail}@${_domain}`),
        );
      }
      return examples;
    },
  },
  mounted() {
    this.attachNode = this.$el;
    this.serverSide = false;
  },
  methods: {
    onUpdateSearchInput(value) {
      if (value !== null) {
        if (value === '') this.formValue = null;
        else this.formValue = value;
      }
    },
    tab(e) {
      if (this.nextComponent) {
        e.stopPropagation();
        e.preventDefault();
        this.nextComponent.focus();
      }
    },
  },
};
