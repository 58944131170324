const middleware = {}

middleware['abTest'] = require('../middleware/abTest.js')
middleware['abTest'] = middleware['abTest'].default || middleware['abTest']

middleware['coursePageRedirect'] = require('../middleware/coursePageRedirect.js')
middleware['coursePageRedirect'] = middleware['coursePageRedirect'].default || middleware['coursePageRedirect']

middleware['coursesGuard'] = require('../middleware/coursesGuard.js')
middleware['coursesGuard'] = middleware['coursesGuard'].default || middleware['coursesGuard']

middleware['detectLandingPage'] = require('../middleware/detectLandingPage.js')
middleware['detectLandingPage'] = middleware['detectLandingPage'].default || middleware['detectLandingPage']

middleware['eventDirections'] = require('../middleware/eventDirections.js')
middleware['eventDirections'] = middleware['eventDirections'].default || middleware['eventDirections']

middleware['nomenclatureTypes'] = require('../middleware/nomenclatureTypes.js')
middleware['nomenclatureTypes'] = middleware['nomenclatureTypes'].default || middleware['nomenclatureTypes']

middleware['pageGuard'] = require('../middleware/pageGuard.js')
middleware['pageGuard'] = middleware['pageGuard'].default || middleware['pageGuard']

middleware['saleGuard'] = require('../middleware/saleGuard.js')
middleware['saleGuard'] = middleware['saleGuard'].default || middleware['saleGuard']

middleware['salesGuard'] = require('../middleware/salesGuard.js')
middleware['salesGuard'] = middleware['salesGuard'].default || middleware['salesGuard']

middleware['scrollUnlock'] = require('../middleware/scrollUnlock.js')
middleware['scrollUnlock'] = middleware['scrollUnlock'].default || middleware['scrollUnlock']

middleware['setAlternateHreflang'] = require('../middleware/setAlternateHreflang.js')
middleware['setAlternateHreflang'] = middleware['setAlternateHreflang'].default || middleware['setAlternateHreflang']

middleware['setSale'] = require('../middleware/setSale.js')
middleware['setSale'] = middleware['setSale'].default || middleware['setSale']

middleware['setSaleDirections'] = require('../middleware/setSaleDirections.js')
middleware['setSaleDirections'] = middleware['setSaleDirections'].default || middleware['setSaleDirections']

middleware['setThankYouSale'] = require('../middleware/setThankYouSale.js')
middleware['setThankYouSale'] = middleware['setThankYouSale'].default || middleware['setThankYouSale']

middleware['topics'] = require('../middleware/topics.js')
middleware['topics'] = middleware['topics'].default || middleware['topics']

export default middleware
