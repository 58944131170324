// Дефолтное значение лимита для запроса списка ивентов
const LIST_LIMIT_DEFAULT = 11;
// При увеличении лимита устанавливается значение:
const LIST_LIMIT_INCREASE = 12;
// Дефолтный оффсет для запроса списка ивентов
const LIST_OFFSET_DEFAULT = 0;

export const state = () => ({
  // Направления вебинаров (Design, Games, Marketing и т.д.)
  directions: [],

  // Имена роутов (без локали. Полное имя выглядит так: webinars-slug___pt-br)
  path: '/webinars',
  routeNames: {
    detailed: 'webinars-slug',
    list: 'webinars-directions',
  },

  // Типы вебинаров (Webinar, Maratona, Workshop и т.д.)
  // ! На данный момент хардкод, с сервера не получаем
  types: [
    'webinar',
    'marathon',
    'success_case',
    'workshop',
    'discussion',
    'demonstration',
    'masterclass',
    'immersion',
  ],

  // Полученный список ивентов
  list: [],

  // Состояние списка ивентов на странице
  listOffset: LIST_OFFSET_DEFAULT,
  listLimit: LIST_LIMIT_DEFAULT,
  more: false,

  // Открыт ли мобильный фильтр
  mobileFiltersModal: false,

  selectedDirectionsIds: [],
  selectedTypeValues: [],
});

export const mutations = {
  // Все существующие направления получаем в app.js из cms
  SET_DIRECTIONS(state, payload) {
    state.directions = payload;
  },
  // При загрузке страницы (например, обновлён фильтр)
  // лимит и оффсет сбрасываются
  RESTORE_LIST_OFFSET_AND_LIMIT(state) {
    state.listOffset = LIST_OFFSET_DEFAULT;
    state.listLimit = LIST_LIMIT_DEFAULT;
  },
  // Увеличить оффсет для запроса при догрузке ивентов
  // Используется при нажатии на кнопку Load More
  INCREASE_LIST_OFFSET(state) {
    const offset = state.listOffset;
    const limit = state.listLimit;

    state.listOffset = offset + limit;
    state.listLimit = LIST_LIMIT_INCREASE;
  },
  // Записать полученный с сервера список ивентов
  SET_LIST(state, { list, offset, more }) {
    state.list = list;
    state.offset = offset;
    state.more = more;
  },
  SET_MOBILE_FILTERS_MODAL(state, payload) {
    state.mobileFiltersModal = payload;
  },
  SET_SELECTED_DIRECTIONS_IDS(state, payload) {
    state.selectedDirectionsIds = [...new Set(payload)];
  },
  SET_SELECTED_TYPE_VALUES(state, payload) {
    state.selectedTypeValues = [...new Set(payload)];
  },
};

export const getters = {
  GET_DIRECTION_BY_SLUG: (state) => (slug) => {
    return state.directions.find((i) => i.eventSlug === slug);
  },
  GET_DIRECTION_BY_ID: (state) => (id) => {
    return state.directions.find((i) => i.id === id);
  },
  GET_DIRECTION_SLUG_FROM_ID: (state) => (id) => {
    return state.directions.find((i) => i.id === id)?.eventSlug;
  },
  GET_DIRECTION_ID_FROM_SLUG: (state) => (eventSlug) => {
    return state.directions.find((i) => i.eventSlug === eventSlug)?.id;
  },

  // Геттеры для селекторов
  GET_DIRECTIONS: (state) => () => {
    const filtered = state.directions.filter(
      (i) => i.id && i.eventsCount && i.eventSlug,
    );
    return filtered;
  },
  GET_TYPES: (state) => (vm) => {
    const types = state.types.map((i) => ({
      label: vm.$t(`webinar.type.${i}`),
      value: i,
    }));

    return types;
  },

  GET_TYPE_BY_VALUE: (state) => (vm) => (value) => {
    const types = getters.GET_TYPES(state)(vm);
    return types.find((i) => i.value === value);
  },

  GET_SELECTED_DIRECTIONS_IDS: (state) => () => {
    return state.selectedDirectionsIds;
  },

  GET_SELECTED_TYPE_VALUES: (state) => () => {
    return state.selectedTypeValues;
  },
};

export const actions = {
  // Запросить с сервера список ивентов с текущим фильтром
  // и записать его в сторе
  async SET_LIST({ commit, state }, { filter, push = false }) {
    const fetchEventsFunction = fetchEvents.bind(this);
    let { list, offset, more } = await fetchEventsFunction(filter);

    // push = true значит список не опустошается
    // и ивенты добавляются к существующим
    if (push) {
      list = [...state.list, ...list];
    }

    commit('SET_LIST', { list, offset, more });
  },
};

async function fetchEvents(filter) {
  try {
    const response = await this.$axios({
      method: 'get',
      url: `${this.$config.CMS_REST_API}/public/v2/events`,
      params: filter,
    });

    const data = response.data.data;

    return {
      list: data.data,
      offset: filter.offset + filter.limit,
      more: filter.offset + filter.limit < data.meta.total,
    };
  } catch (e) {
    console.error(e);
  }
}
